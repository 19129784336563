@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --primary-color: #000000;
  --secondary-color: #ffffff;
  --background-color: #f8f8f8;
  --text-color: #333333;
  --accent-color: #cccccc;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--secondary-color);
  color: var(--text-color);
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--secondary-color);
  padding: 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.App-header h1 {
  margin: 0;
  color: var(--primary-color);
  font-size: 2.2rem;
  font-weight: 700;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--primary-color);
  text-decoration: none;
  transition: opacity 0.3s ease;
}

nav ul li a:hover {
  opacity: 0.7;
}

main {
  max-width: 1200px;
  margin: 80px auto 0;
  padding: 20px;
}

section {
  margin: 80px 0;
  padding: 60px 0;
}

h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--primary-color);
}

.hero {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  padding: 180px 0;
  position: relative;
  overflow: hidden;
}

.hero::before {
  display: none; /* Remove the gradient overlay */
}

.hero-content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.hero h2 {
  font-size: 4.5rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  color: var(--primary-color);
}

.hero h2::after {
  display: none;
}

.tagline {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 1.3;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: var(--text-color);
}

.cta-button {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 18px 36px;
  text-decoration: none;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  letter-spacing: 0.5px;
}

.cta-button:hover {
  background-color: var(--text-color);
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.coming-soon {
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
  padding: 40px;
  margin: 40px 0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.coming-soon h3 {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-top: 0;
}

.waitlist-button, .submit-button {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.waitlist-button:hover, .submit-button:hover {
  background-color: var(--text-color);
  transform: translateY(-2px);
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}

input, textarea {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  width: 100%;
  margin: 10px 0;
  padding: 12px;
  border: 1px solid var(--accent-color);
  border-radius: 5px;
}

textarea {
  height: 150px;
}

footer {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  text-align: center;
  padding: 40px 20px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
  }

  nav ul {
    margin-top: 20px;
  }

  nav ul li {
    margin: 10px 15px;
  }

  .hero {
    padding: 120px 0;
  }

  .hero h2 {
    font-size: 3.2rem;
  }

  .tagline {
    font-size: 1.8rem;
  }

  .cta-button {
    padding: 15px 30px;
    font-size: 1.1rem;
  }

  section {
    padding: 40px 0;
  }

  h2 {
    font-size: 2.5rem;
  }

  .coming-soon h3 {
    font-size: 2rem;
  }
}

section:not(.hero) {
  background-color: var(--background-color);
}
